.ag-grid-table {
  height: 100%;

  /* We need to override library default style. */
  /* stylelint-disable-next-line declaration-no-important */
  --ag-line-height: 32px !important;
  --ag-row-height: 30px;
  --ag-active-color: var(--primary-color);
  --ag-font-family: var(--font-family);
  --ag-cell-horizontal-border: 1px solid var(--table-cell-border-color);
  --ag-cell-horizontal-padding: var(--space-s);
  --ag-header-background-color: var(--table-header-background-color);
  --ag-header-foreground-color: var(--font-contrast-color);
  --ag-row-hover-color: color-mix(
    in srgb,
    transparent,
    var(--table-row-hover-color) 20%
  );
  --ag-value-change-value-highlight-background-color: var(
    --ag-range-selection-background-color
  );
  --ag-header-column-resize-handle-height: 40%;
  --ag-header-column-resize-handle-width: 1px;
  --ag-header-column-separator-display: block;
  --ag-header-column-separator-color: var(--table-header-border-color);
  --ag-control-panel-background-color: var(--ag-background-color);
  --ag-header-column-resize-handle-color: var(--background-color);
  --cell-height: calc(var(--ag-row-height) - var(--ag-row-border-width) * 2);
  --header-text-font-size: 0.9rem;
  --horizontal-border-width: 0;

  .ag-root-wrapper {
    border-left-width: var(--horizontal-border-width);
    border-right-width: var(--horizontal-border-width);
  }

  .ag-header-row {
    --ag-border-color: var(--table-header-border-color);
  }

  .ag-header-cell.ag-header-span-height .ag-header-cell-resize::after {
    --ag-header-column-resize-handle-height: 30px;

    height: var(--ag-header-column-resize-handle-height);
    top: calc(50% - var(--ag-header-column-resize-handle-height) / 2);
  }

  .ag-pinned-left-cols-container .ag-row-position-absolute::after {
    content: "";
    position: absolute;
    height: 100%;
    right: 0;
    border-right: 1px solid var(--table-fixed-column-border-color);
    // Fixed column might have editable cell. Border shouldn't be hidden by cell editor.
    z-index: 1000;
  }

  .ag-center-cols-container,
  .ag-floating-bottom-container {
    min-width: 100%;
  }

  .ag-center-cols-viewport {
    min-height: auto;
  }

  .ag-pinned-right-cols-container {
    border-left: 1px solid var(--table-fixed-column-border-color);
  }

  .ag-floating-bottom {
    border-top: 1px solid var(--table-fixed-row-border-color);
  }

  .ag-cell-inline-editing:not(.ag-cell-focus) {
    --ag-input-focus-border-color: transparent;
  }

  .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
    border-color: var(--table-fixed-row-border-color);
  }

  .ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
    border-color: var(--ag-range-selection-border-color);
  }

  .custom-cell_no-horizontal-padding:not(.ag-header-group-cell) {
    padding-left: 0;
    padding-right: 0;
  }

  .overridable-cell {
    position: relative;

    --reset-button-padding: 20px;
  }

  .ag-cell-highlight.ag-cell-data-changed {
    --ag-range-selection-highlight-color: var(--warn-light-color);
  }

  .wanted-cell::before {
    width: 100%;
    height: 100%;
    content: "";
    right: 0;
    background-color: var(--search-matched-color);
    position: absolute;
    opacity: 0.3;
  }

  .wanted-cell_active::before {
    background-color: var(--search-active-color);
  }

  .overridable-cell_has-override {
    position: relative;
    background-color: var(--control-reset-available-color);
  }

  .cell-reset {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: var(--reset-button-padding);
  }

  .cell-reset .reset-override-button {
    --icon-size: 20px;
    --radius: 50%;

    width: var(--icon-size);
    height: var(--icon-size);
    font-size: calc(0.8 * var(--icon-size));
    color: var(--ag-data-color);
  }

  .table-cell_align-left {
    .overridable-cell_has-override.custom-cell_renderer,
    .overridable-cell_has-override.custom-cell_editor .input {
      padding-right: var(--reset-button-padding);
    }

    .cell-reset {
      right: 0;
    }
  }

  .table-cell_align-center {
    text-align: center;

    .overridable-cell_has-override.custom-cell_renderer,
    .overridable-cell_has-override.custom-cell_editor .input {
      padding-left: var(--reset-button-padding);
      padding-right: var(--reset-button-padding);
    }

    .cell-reset {
      right: 0;
    }
  }

  .table-cell_align-right {
    text-align: right;

    .overridable-cell_has-override.custom-cell_renderer,
    .overridable-cell_has-override.custom-cell_editor .input {
      padding-left: var(--reset-button-padding);
    }

    .cell-reset {
      left: 0;
    }
  }

  .custom-cell_renderer.has-error,
  .custom-cell_editor.has-error {
    outline: 2px solid var(--warn-color);
    outline-offset: -2px;
  }

  .ag-header-group-text {
    font-size: var(--header-text-font-size);
  }

  .table-cell_align-center .ag-input-field-input {
    text-align: center;
    padding-left: var(--ag-grid-size);
    padding-right: var(--ag-grid-size);
  }

  .table-cell_align-right .ag-input-field-input {
    text-align: right;
    padding-left: var(--ag-grid-size);
    padding-right: var(--ag-grid-size);
  }

  .table-cell_editable {
    outline: 1px dashed var(--table-editable-cell-border);
    outline-offset: -2px;
  }
}
