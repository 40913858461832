/*
 * By defining property as `<color>` we can use it in `transition` property.
 * It's need to animate highlight animation (blue pulsation),
 * `linear-gradient` doesn't support it.
 */
@property --second-color {
  syntax: "<color>";
  initial-value: white;
  inherits: false;
}

/** Applies background without taking into account left padding of the cell. */
.dynamic-background {
  --first-color: var(--padding-color, var(--background-color));
  --second-color: var(--color, var(--background-accent-color));

  overflow: visible;
  background: linear-gradient(
    90deg,
    var(--first-color) 0%,
    var(--first-color) var(--padding),
    var(--second-color) var(--padding),
    var(--second-color) 100% /* Selected rows have */
      /* stylelint-disable-next-line declaration-no-important */
  ) !important;
}

/* Highlight (when copy) */

/*
 * 1. `.ag-cell-highlight` applied, we instantly change bg.
 * 2. `.ag-cell-highlight` removed, `.ag-cell-highlight-animation` applied,.
 * 3. `.ag-cell-highlight-animation` removed, bg transitions to selection color.
 */
.dynamic-background.ag-cell-highlight,
.dynamic-background.ag-cell-highlight.ag-cell-range-selected-1:not(
    .ag-cell-focus
  ) {
  --second-color: var(--ag-range-selection-highlight-color);
}

.dynamic-background.ag-cell-highlight-animation {
  --second-color: var(--ag-range-selection-background-color);

  /* ag-grid transition applied in `style` attr for highlight animation. */
  /* stylelint-disable-next-line declaration-no-important */
  transition: --second-color 0.5s ease-in-out !important;
}

/* Range selection. */

.dynamic-background.ag-cell-range-selected-1:not(.ag-cell-focus) {
  --second-color: var(--ag-range-selection-background-color);
}

/*
 * Remove border for range selection, because it's impossible to draw
 * a proper border for range containing cells with dynamic-background.
 */
.ag-cell.ag-cell-range-selected:not(
    .ag-cell-range-single-cell
  ).ag-cell-range-top,
.ag-cell.ag-cell-range-selected:not(
    .ag-cell-range-single-cell
  ).ag-cell-range-right,
.ag-cell.ag-cell-range-selected:not(
    .ag-cell-range-single-cell
  ).ag-cell-range-bottom,
:not(.dynamic-background).ag-cell.ag-cell-range-selected:not(
    .ag-cell-range-single-cell
  ).ag-cell-range-left {
  border-color: transparent;
}

/* Cells has row background underneath, transparent border will reveal it. */
.dynamic-background.ag-cell.ag-cell-range-selected:not(
    .ag-cell-range-single-cell
  ).ag-cell-range-left {
  border-left-color: var(--background-color);
}

/* Custom input border for cells with padding. */

.ag-ltr .dynamic-border.ag-cell-range-single-cell {
  position: relative;
  border-block-color: transparent;
  border-left-color: var(--background-color, transparent);
  overflow: visible;
}

.dynamic-border.ag-cell-inline-editing {
  /* ag-grid uses `!important` for `.ag-cell-inline-editing` */
  /* stylelint-disable-next-line declaration-no-important */
  border-block-color: transparent !important;
  /* stylelint-disable-next-line declaration-no-important */
  border-left-color: var(--background-color, transparent) !important;
}

.ag-ltr .dynamic-border.ag-cell-range-single-cell::after {
  content: "";
  outline: 1px solid var(--ag-range-selection-border-color);
  position: absolute;
  inset: 0;
  left: var(--border-padding);
  pointer-events: none;
}

/* Checkbox row selection */

/*
 * Selected rows have overlay with low opacity that adds a bit of tint to them.
 * We copy styles from row element to element with dynamic background,
 * because elements with dynamic background has not transparent background.
 */
.ag-row-selected:has(.dynamic-background)::before {
  display: none;
}

.ag-row-selected .dynamic-background::before {
  content: "";
  background-color: var(--ag-selected-row-background-color);
  display: block;
  position: absolute;
  inset: -1px;
  left: var(--padding, 0);
  pointer-events: none;
}
