.cdk-drag-preview {
  box-shadow:
    0 5px 5px -3px rgb(0 0 0 / 0.2),
    0 8px 10px 1px rgb(0 0 0 / 0.14),
    0 3px 14px 2px rgb(0 0 0 / 0.12);
  max-height: max-content;
}

// Base-estimate systems page notes list.
.notes:not(.cdk-drop-list-dragging) .note:hover {
  background-color: var(--table-row-highlight-hover-color);
}

.notes:not(.cdk-drop-list-dragging) .note:hover .drag__icon {
  visibility: visible;
}
