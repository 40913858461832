.points-list-label-tabs .mdc-tab {
  padding: 0;
  min-width: 40px;
  border-right: 1px solid var(--faint-color);
}

.points-list-label-tabs .mdc-tab--active {
  background-color: var(--background-color);
}

.points-list-label-tabs .mdc-tab .mdc-tab-indicator {
  height: 2px;
}

.points-list-label-tabs .mat-mdc-tab-header,
.points-list-content-tabs .mat-mdc-tab-header {
  border: 0;
}

.points-list-content-tabs .mat-mdc-tab-label-container {
  display: none;
}

.points-list-content-tabs .mat-mdc-tab-body-wrapper {
  height: 100%;
}
