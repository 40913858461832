/*
  'mat-slide-toggle' has bug which break styles override.
  See this GitHub issue: https://github.com/angular/components/issues/26818.
*/
.mat-mdc-slide-toggle {
  // Selected track style.
  --selected-track-color: rgb(19 99 146 / 0.4); // this is primary color.
  --mdc-switch-selected-track-color: var(--selected-track-color);
  --mdc-switch-selected-hover-track-color: var(--selected-track-color);
  --mdc-switch-selected-focus-track-color: var(--selected-track-color);
  --mdc-switch-selected-pressed-track-color: var(--selected-track-color);

  // Unselected track style.
  --unselected-track-color: rgb(0 0 0 / 0.4);
  --mdc-switch-unselected-track-color: var(--unselected-track-color);
  --mdc-switch-unselected-hover-track-color: var(--unselected-track-color);
  --mdc-switch-unselected-focus-track-color: var(--unselected-track-color);
  --mdc-switch-unselected-pressed-track-color: var(--unselected-track-color);

  // Selected state style.
  --mdc-switch-selected-state-layer-color: var(--primary-color);
  --mdc-switch-selected-hover-state-layer-color: var(--primary-color);
  --mdc-switch-selected-focus-state-layer-color: var(--primary-color);
  --mdc-switch-selected-pressed-state-layer-color: var(--primary-color);

  // Unselected handle style.
  --mdc-switch-unselected-handle-color: var(--primary-contrast-color);
  --mdc-switch-unselected-hover-handle-color: var(--primary-contrast-color);
  --mdc-switch-unselected-focus-handle-color: var(--primary-contrast-color);
  --mdc-switch-unselected-pressed-handle-color: var(--primary-contrast-color);

  // Selected handle style.
  --mdc-switch-selected-handle-color: var(--primary-color);
  --mdc-switch-selected-hover-handle-color: var(--primary-color);
  --mdc-switch-selected-focus-handle-color: var(--primary-color);
  --mdc-switch-selected-pressed-handle-color: var(--primary-color);
  --mdc-switch-handle-surface-color: var(--primary-background-color);
}

.mdc-switch--disabled {
  --disabled-track-color: rgb(0 0 0 / 0.1);
  --mdc-switch-handle-surface-color: transparent;

  // Selected track style.
  --mdc-switch-selected-track-color: var(--disabled-track-color);
  --mdc-switch-selected-hover-track-color: var(--disabled-track-color);
  --mdc-switch-selected-focus-track-color: var(--disabled-track-color);
  --mdc-switch-selected-pressed-track-color: var(--disabled-track-color);

  // Unselected track style.
  --mdc-switch-unselected-track-color: var(--disabled-track-color);
  --mdc-switch-unselected-hover-track-color: var(--disabled-track-color);
  --mdc-switch-unselected-focus-track-color: var(--disabled-track-color);
  --mdc-switch-unselected-pressed-track-color: var(--disabled-track-color);
}
