// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.elevation-classes();
@include mat.app-background();

// TODO (Material v15 migration): Use CSS custom properties for styling.
// Since Angular Material v15 CSS custom properties can't be used as theme options.
// See related GitHub issue: https://github.com/angular/components/issues/25981.
// Theme colors are defined in variables.css.

$web-primary-colors: (
  main: #136392,
  dark: #063563,
  light: #e3ecf2,
  contrast: (
    main: black,
    dark: black,
    light: white,
  ),
);

$web-accent-colors: (
  main: #dadde0,
  dark: #4c4c4c,
  light: #dadde0,
  contrast: (
    main: #6d6f70,
    dark: white,
    light: #6d6f70,
  ),
);

/*
  Signature of mat-palette.
  mat.define-palette(
    scss variable with shape { key1: color, key2: color, key3: color, contrast: { ...repeat all keys } }, (example above)
    key of primary color,
    key of darken color,
    key of lighten color,
    (optional) key of primary contrast - default contrast[key_of_primary_color]
  )
*/
$web-primary: mat.m2-define-palette($web-primary-colors, main, dark, light);
$web-accent: mat.m2-define-palette($web-accent-colors, main, dark, light);

$web-typography-level: mat.m2-define-typography-level(
  $font-size: 14px,
  $letter-spacing: 0.2px,
);
$web-typography: mat.m2-define-typography-config(
  $font-family: Heebo,
  $body-1: $web-typography-level,
  $button: $web-typography-level,
);

$web-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $web-primary,
      accent: $web-accent,
    ),
    typography: $web-typography,
    density: 0,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($web-theme);
