.amount-input-container,
.masked-input-container {
  position: relative;
  display: flex;
  overflow: hidden;
  align-items: center;
  min-width: min-content;
  border: 1px solid var(--control-border-color);
  border-radius: var(--radius);
  background-color: var(--background-color);
  color: var(--faint-color);
  height: 100%;
}

.amount-input {
  width: 100%;
  height: 100%;
  padding: calc(var(--space-xs) * 1.25) var(--space-s)
    calc(var(--space-xs) * 1.25) var(--space-s);
  border: 0;
}

.amount-input_reset-available {
  background-color: var(--control-reset-available-color);
}

.amount-input:disabled {
  color: var(--font-primary-light-color);
  background-color: var(--control-disabled-background-color);
}

.amount-input-container > .reset-button {
  --icon-size: 20px;
  --radius: 50%;

  position: absolute;
  right: var(--space-xs);
  width: var(--icon-size);
  height: var(--icon-size);
  font-size: calc(0.8 * var(--icon-size));
}

.masked-input-container .amount-input {
  padding: calc(var(--space-xs) * 1.5) var(--space-m);
}

.masked-input-container .amount-input_has-suffix {
  padding-right: calc(2 * var(--space-m));
}

.masked-input-container > .suffix {
  position: absolute;
  right: var(--space-s);
}
