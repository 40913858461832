input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

input[type="number"] {
  appearance: textfield;
}

.mat-mdc-form-field-textarea-control.no-resize-textarea {
  resize: none;
}

.mat-form-field-appearance-outline
  .mat-mdc-text-field-wrapper:has(.input_reset-available) {
  background-color: var(--control-reset-available-color);
}
