:root {
  /* Elements spacing. */
  --space-m: 16px;
  --space-xxs: calc(var(--space-xs) * 0.5);
  --space-xs: calc(var(--space-m) * 0.25);
  --space-s: calc(var(--space-m) * 0.5);
  --space-l: calc(var(--space-m) * 1.5);
  --space-xl: calc(var(--space-m) * 2.5);
  --page-horizontal-padding: var(--space-m);
  --header-horizontal-padding: calc(-1 * var(--page-horizontal-padding));
}
