.estimate-select-button.mat-mdc-button-base .mdc-button__label {
  width: 100%;
}

.estimate-select-menu.mat-mdc-menu-panel.mat-mdc-menu-panel {
  --width: calc(450px - 2 * var(--space-xl));

  max-height: 500px;
  min-width: var(--width);
  max-width: var(--width);
}
