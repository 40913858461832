.mat-mdc-chip-grid.mdc-evolution-chip-set {
  .mat-mdc-chip-input {
    flex-basis: 100px;
  }

  .mdc-evolution-chip {
    margin: calc(0.5 * var(--space-xs));
    border-radius: var(--radius);
  }

  .mdc-evolution-chip-set__chips {
    margin: calc(-1.9 * var(--space-xs)) 0;
    margin-left: calc(-1 * var(--space-xs));
  }

  .mdc-evolution-chip__cell--primary,
  .mdc-evolution-chip__action--primary,
  .mat-mdc-chip-action-label {
    overflow: hidden;
  }

  .mat-mdc-chip-action-label {
    text-overflow: ellipsis;
  }

  .mat-mdc-input-element {
    min-height: 2.6em;
  }
}

.mat-mdc-chip-listbox.mdc-evolution-chip-set {
  .mdc-evolution-chip__cell--primary,
  .mdc-evolution-chip__action--primary,
  .mat-mdc-chip-action-label {
    overflow: hidden;
  }
}
