.project-sidebar-content .app-page-content.no-scroll {
  overflow: hidden;
}

.header .xng-breadcrumb-root {
  color: var(--breadcrumb-item-color);
}

.header .xng-breadcrumb-trail {
  color: var(--breadcrumb-trailing-item-color);
}
