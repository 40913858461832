.multi-filters-dialog .filters-form {
  display: grid;
  place-items: flex-start center;
  justify-content: space-evenly;
  grid-template-columns: 75px 200px 150px 245px 30px 30px;
  column-gap: var(--space-xs);
}

.multi-filters-dialog .filters-form__header {
  justify-items: flex-start;
  font-size: 1.1rem;
  font-weight: 500;
}

.multi-filters-dialog .filters-form__button {
  --btn-size: 20px;

  margin-top: calc(1.5 * var(--space-m));
  height: var(--btn-size);
  width: var(--btn-size);
  font-size: calc(var(--btn-size));
  color: var(--background-faint-color);
}

.multi-filters-dialog .remove-button {
  --btn-size: 24px;
}

.multi-filters-dialog .input-wrapper {
  width: 100%;
}

.multi-filters-dialog .add-fields-button {
  width: 100%;
  height: 60px;
  margin-top: var(--space-s);
  color: var(--new-primary-color);
}

.multi-filters-dialog .add-fields-button__icon {
  --icon-size: 20px;

  display: flex;
  align-items: center;
  width: var(--icon-size);
  height: var(--icon-size);
  font-size: var(--icon-size);
}

.multi-filters-dialog .close-button {
  background-color: var(--accent-light-color);
  color: var(--accent-light-contrast-color);
}
