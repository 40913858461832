.project-editable-table
  .table-body:not(.cdk-drop-list-dragging)
  .table-row_allow-hover:hover
  td:not(.disabled-cell, .group-footer-cell) {
  background-color: var(--table-row-hover-color);
}

.project-editable-table
  .table-body:not(.cdk-drop-list-dragging)
  .table-row_allow-hover:hover
  .delete-button,
.project-editable-table
  .table-body:not(.cdk-drop-list-dragging)
  .table-row_allow-hover:hover
  .drag-icon {
  visibility: visible;
}

.project-editable-table .delete-button {
  --btn-size: 12px;

  height: var(--btn-size);
  width: var(--btn-size);
  font-size: var(--btn-size);
  color: var(--background-faint-color);
  visibility: hidden;
}

.project-editable-table .delete-button__icon {
  background-color: var(--warn-color);
  color: var(--primary-contrast-color);
  border-radius: var(--radius);
}

.project-editable-table .select .mat-mdc-select-trigger {
  height: 100%;
  padding: 0 var(--space-s);
}

/* Style for header amount type and markup type select at editable table header. */
.header-units-select .mat-mdc-select-value {
  --header-select-height: calc(var(--cell-height) + 3px);

  height: var(--header-select-height);
  visibility: hidden;
}

.units-select-panel.mdc-menu-surface.mat-mdc-select-panel {
  padding: 0;
  border-radius: 0;
  box-shadow: none;
}

.header-units-select .mat-mdc-select-arrow {
  color: var(--faint-light-color);
}

.header-units-select .mat-mdc-select-arrow-wrapper {
  padding-right: var(--space-xs);
  overflow: hidden;
}

.project-editable-table .cdk-drag-handle:not(.table-cell_disabled-drop) {
  cursor: move;
}

dartsalesc-editable-table:not(.resources-table) *:not(thead, tfoot) {
  .table-row:not(.table-row_creating) {
    dartsalesc-editable-table-cell:not(.readonly),
    .dartsalesc-cell-wrapper:not(.readonly) {
      outline: 1px dashed var(--table-editable-cell-border);
      outline-offset: -2px;
    }
  }
}

[dartsalesctable] {
  .table-row:not(.system-note-row, .table-row_creating) {
    dartsalesc-editable-table-cell:not(.readonly),
    .dartsalesc-cell-wrapper:not(.readonly) {
      outline: 1px dashed var(--table-editable-cell-border);
      outline-offset: -2px;
    }
  }
}

.points-list-table .ag-row:not(.ag-full-width-row) {
  dartsalesc-editable-table-cell:not(.readonly),
  .dartsalesc-cell-wrapper:not(.readonly) {
    outline: 1px dashed var(--table-editable-cell-border);
    outline-offset: -1px;
  }
}

.point-list-table-row_crossed-out,
.point-list-table-row_crossed-out .masked-number-renderer__content {
  text-decoration: line-through;
}

.final-estimate-services-table-row__bolded {
  font-weight: bold;
}
