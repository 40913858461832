.mat-mdc-menu-panel .mat-mdc-menu-content {
  padding: 0;
}

.labor-role-type-option_nested .mat-mdc-menu-item {
  padding: 0;
}

.mat-mdc-menu-panel:has(.labor-role-type-option) {
  border-radius: 0;
}

.labor-role-type-option.mat-mdc-menu-item {
  --menu-width: 120px;

  justify-content: center;
  width: var(--menu-width);
  height: 48px;

  .mat-mdc-menu-item-text,
  .mdc-list-item__primary-text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:not([disabled]).mat-mdc-menu-item-highlighted,
  &:not([disabled]):hover,
  .mat-mdc-option.mdc-list-item--selected:not(
      .mdc-list-item--disabled,
      .mat-mdc-option-multiple
    ) {
    background-color: var(--table-header-color);
  }

  & .mat-mdc-menu-submenu-icon,
  .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
    .mdc-list-item__primary-text,
  .mat-mdc-option {
    --mdc-list-list-item-label-text-color: var(--font-contrast-color);
    --mdc-list-list-item-hover-label-text-color: var(--font-contrast-color);

    color: var(--font-contrast-color);
  }

  & .mat-mdc-option {
    width: var(--menu-width);
  }
}
