*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--background-light-contrast-color);
  border-radius: var(--radius);
}

.custom-scroll::-webkit-scrollbar,
.custom-scroll::-webkit-scrollbar-corner {
  width: 6px;
  background-color: var(--table-header-background-color);
}
