.mdc-checkbox .mat-mdc-checkbox-touch-target {
  width: 100%;
  height: 100%;
}

.mat-mdc-checkbox .mdc-form-field {
  color: inherit;
}

.mat-mdc-checkbox.mat-accent.group-name-cell__checkbox
  .mdc-checkbox
  .mdc-checkbox__background {
  --mdc-checkbox-unselected-hover-icon-color: var(
    --mdc-checkbox-selected-icon-color
  );
  --mdc-checkbox-unselected-icon-color: var(--mdc-checkbox-selected-icon-color);
  --mdc-checkbox-unselected-focus-icon-color: var(
    --mdc-checkbox-selected-icon-color
  );
  --mdc-checkbox-unselected-pressed-icon-color: var(
    --mdc-checkbox-selected-icon-color
  );
}

.mat-mdc-checkbox.mat-primary .mdc-checkbox__mixedmark {
  --mdc-checkbox-selected-checkmark-color: var(--primary-contrast-color);
}

.mat-mdc-checkbox.mat-primary
  .mdc-form-field.mat-internal-form-field
  .mdc-checkbox
  .mdc-checkbox__background
  .mdc-checkbox__checkmark {
  color: rgb(255 255 255);
}

.mat-mdc-list-item.mat-mdc-list-option.mdc-list-item
  .mdc-list-item__start.mat-mdc-list-option-checkbox-before
  .mdc-checkbox
  .mdc-checkbox__background
  .mdc-checkbox__checkmark {
  color: rgb(255 255 255);
}

.mat-pseudo-checkbox.mat-mdc-option-pseudo-checkbox.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal {
  display: none;
}
