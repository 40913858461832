.filters-form__operator .mat-mdc-select-value {
  text-align: center;
  font-weight: 500;
}

.filters-form__operator .mat-mdc-text-field-wrapper {
  background-color: var(--background-light-faint-color);
}

.search-highlight-wrapper {
  display: inline-block;
}

.search-highlight {
  background-color: var(--search-matched-color);
  display: inline-block;
}

.search-highlight_active {
  background-color: var(--search-active-color);
}

.page-header__search .mat-mdc-text-field-wrapper {
  background-color: var(--background-color);
}

.page-header__search .mat-mdc-chip-listbox {
  display: flex;
  justify-content: center;
}
