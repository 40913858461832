.mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: var(--background-color);
  --mdc-snackbar-supporting-text-color: var(--font-primary-color);
}

.mat-mdc-snack-bar-container
  .mat-mdc-button.mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
  color: var(--mdc-text-button-label-text-color, inherit);
}

.mdc-snackbar .mdc-snackbar__surface {
  padding: 0;
}

.file-upload-message {
  white-space: pre-line;
}
