.mat-mdc-select-panel.invisible-select-panel {
  visibility: hidden;
}

.multi-filters-dialog-menu__button .mdc-list-item__primary-text {
  width: 100%;
}

.multi-filters-dialog-menu__button .mdc-list-item--selected {
  width: 100%;
}

.multi-filters-dialog-menu--nested .mat-mdc-menu-item {
  padding: 0;
}

.files-table__action-menu {
  width: 170px;
}

.files-table__action-menu .mat-mdc-menu-content {
  padding: 0;
}

.files-table__action-menu .mat-mdc-menu-item.mdc-list-item {
  min-height: 40px;
}

.files-table__action-menu .mat-mdc-menu-item.mdc-list-item:not(:last-child) {
  border-bottom: 1px solid #dedede;
}

.files-table__action-menu .mat-mdc-menu-item.mdc-list-item.accent .mat-icon,
.files-table__action-menu
  .mat-mdc-menu-item.mdc-list-item.accent
  .mdc-list-item__primary-text {
  color: var(--warn-color);
}

.files-table__action-menu .mat-mdc-menu-item.mdc-list-item .mat-icon {
  color: rgb(0 0 0 / 0.6);
}

.files-table__action-menu
  .mat-mdc-menu-item.mdc-list-item
  .mdc-list-item__primary-text {
  --mdc-list-list-item-label-text-color: var(--font-primary-color);
  --mdc-list-list-item-hover-label-text-color: var(--font-primary-color);

  color: var(--font-primary-color);
}
