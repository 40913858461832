.change-order-table {
  .mat-sort-header-arrow {
    color: var(--table-cell-color);
  }

  .mat-sort-header-container {
    justify-content: space-between;
  }

  .mat-sort-header-content {
    text-align: left;
  }
}
