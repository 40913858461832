.mdc-list-item__content .mdc-list-item__primary-text {
  --mdc-list-list-item-label-text-color: var(--primary-light-contrast-color);
  --mdc-list-list-item-hover-label-text-color: var(
    --primary-light-contrast-color
  );

  display: flex;
  align-items: center;
  color: var(--primary-light-contrast-color);
}

.group-management-menu .mdc-list-item__content .mdc-list-item__primary-text {
  justify-content: space-between;
}
