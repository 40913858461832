[dartsalescTable] {
  overflow: auto;
  position: relative;

  --loader-elevation-index: 20;
  --table-header-elevation-index: 10;
  --sticky-cell-elevation-index: 3;
}

[dartsalescTable] .table__loading {
  position: sticky;
  top: 0;
  left: 0;
  z-index: var(--loader-elevation-index);
}

[dartsalescTable] .table-row {
  display: flex;
  width: min-content;
}

[dartsalescTable] .table-row .table-cell {
  --cell-border: 1px solid
    var(--table-cell-border-color, var(--table-cell-border-color));

  /* We subtract 1px because of table borders. */
  --cell-min-height: calc(var(--cell-height) - 1px);

  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  padding: 0;
  height: var(--cell-height);
  border-right: var(--cell-border);
  border-bottom: var(--cell-border);
  align-items: center;
  color: var(--table-cell-color, var(--table-font-color));
  background-color: var(--table-cell-background-color, var(--background-color));
}

[dartsalescTable] .table-row :nth-child(1 of .table-cell) {
  border-left: 1px solid var(--table-cell-border-dark-color);
}

[dartsalescTable] .table-row :nth-last-child(1 of .table-cell) {
  border-right: 1px solid var(--table-cell-border-dark-color);
}

[dartsalescTable] .table-cell.table-cell_sticky {
  position: sticky;
  z-index: var(--sticky-cell-elevation-index);
  box-shadow: 1px 0 0 0 black;
}

[dartsalescTable] .table-cell_align-center {
  justify-content: center;
  text-align: center;
}

[dartsalescTable] .table-cell_align-right {
  justify-content: right;
  text-align: right;
}

[dartsalescTable]
  .table-cell.table-cell_sticky:nth-child(1 of .table-cell_sticky-right) {
  box-shadow: -1px 0 0 0 black;
}

[dartsalescTable] .table-cell.table-cell_invisible {
  display: none;
}

[dartsalescTable] .table-row:hover {
  --table-cell-background-color: var(
    --table-cell-hover-background-color,
    var(--table-row-hover-color)
  );
}

[dartsalescTable] .table-row.table-row_nested-3 {
  --table-cell-background-color: var(--table-custom-cell-background-color);
  --table-cell-highlight-background-color: var(--table-row-highlight-color);
  --table-cell-highlight-hover-background-color: var(
    --table-row-highlight-color
  );
}

[dartsalescTable] .table-row.table-row_nested-2 {
  --table-cell-color: var(--accent-dark-contrast-color);
  --table-cell-background-color: var(--table-group-row-color);
  --table-cell-border-color: var(--table-group-border-color);
  --table-cell-highlight-background-color: var(
    --table-row-highlight-dark-color
  );
  --table-cell-highlight-hover-background-color: var(
    --table-row-highlight-dark-color
  );
}

[dartsalescTable] .table-row.table-row_nested-1 {
  --table-cell-color: var(--accent-dark-color);
  --table-cell-background-color: var(--background-accent-color);
  --table-cell-border-color: var(--table-nested-group-border-color);
  --table-cell-highlight-background-color: var(
    --table-row-highlight-middle-color
  );
  --table-cell-highlight-hover-background-color: var(
    --table-row-highlight-middle-color
  );
}

[dartsalescTable] .table-row.table-row_highlighted {
  --table-cell-background-color: var(
    --table-cell-highlight-background-color,
    var(--table-row-highlight-color)
  );
}

[dartsalescTable] .table-row.table-row_highlighted:hover {
  --table-cell-background-color: var(
    --table-cell-highlight-hover-background-color,
    var(--table-row-highlight-hover-color)
  );
}

[dartsalescTable] .table-row.table-row_header {
  position: sticky;
  top: 0;
  z-index: var(--table-header-elevation-index);
}

[dartsalescTable] .table-row.table-row_header .table-cell {
  --table-cell-color: var(--font-contrast-color);
  --table-cell-background-color: var(--table-header-background-color);
  --table-cell-border-color: var(--table-header-border-color);

  padding: 0 var(--space-s);
  font-weight: normal;
  font-size: 0.9rem;
  height: 100%;
  min-height: var(--cell-height);
}

[dartsalescTable] .table-row.table-row_header .table-cell-with-sorting {
  display: flex;
  align-items: center;
  gap: var(--space-xs);
  justify-content: inherit;
  width: 100%;
}

[dartsalescTable] .table-cell .mat-mdc-checkbox {
  --mdc-checkbox-state-layer-size: var(--cell-min-height);
}

[dartsalescTable] .table-cell .mat-mdc-slide-toggle {
  --mdc-switch-state-layer-size: var(--cell-min-height);
}

.drag-preview {
  /*
    We meed to hide this element.
    It's a limitation of native drag'n'drop.
    See https://developer.mozilla.org/en-US/docs/Web/API/DataTransfer/setDragImage.
    Also note that we use element positioning hack so element is always inside the viewport.
    If element is outside the viewport drag preview will be invisible.
  */
  --drag-preview-height: 40px;

  position: fixed;
  top: calc(-1 * var(--drag-preview-height) + 1px);
  left: 0;
  display: flex;
  align-items: center;
  height: var(--drag-preview-height);
  max-width: 200px;
  padding: 0 var(--space-s);
  background-color: var(--background-color);
  border-radius: var(--radius);
}

.drag-handle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.drag-handle__icon {
  color: var(--background-faint-color);
  visibility: hidden;
}

.drag-handle_accent .drag-handle__icon {
  color: var(--accent-color);
}

.table-row:hover .drag-handle__icon {
  visibility: visible;
}

.ag-cell:hover .drag-handle:not(.dndDraggableDisabled) .drag-handle__icon {
  visibility: visible;
}

.ag-cell:hover .draggable-row.dndDraggableDisabled .drag-handle__icon {
  visibility: hidden;
}

.dndDraggableDisabled .table-row:hover .drag-handle__icon {
  visibility: hidden;
}

.drag-placeholder_line {
  height: 2px;
  background-color: var(--primary-color);
}

.highlighted::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-color: var(--control-reset-available-color);

  /* To overlap input background. */
  z-index: 1;
}
