@import "./variables.css";
@import "./elements/index.css";

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  margin: 0;
  background: var(--background-color);
  color: var(--background-contrast-color);
  font-family: var(--font-family);
  font-size: var(--font-size);
}

.visually-hidden {
  /*
    To preserve a11y, hide radio button only visually
    https://www.sarasoueidan.com/blog/inclusively-hiding-and-styling-checkboxes-and-radio-buttons/
  */
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  margin: 0;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: var(--line-clamp-lines-amount, 4);
  -webkit-box-orient: vertical;
  overflow: hidden;
}
