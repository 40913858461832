a.mat-mdc-icon-button,
button.mat-mdc-icon-button {
  border-radius: calc(2 * var(--radius));
}

.mat-mdc-button.mat-mdc-button-base,
.mat-mdc-icon-button.mat-mdc-button-base {
  .mat-mdc-button-touch-target {
    width: 100%;
    height: 100%;
  }
}

.mat-mdc-button-base.mat-mdc-icon-button {
  text-align: center;

  .mat-mdc-button-persistent-ripple {
    border-radius: calc(2 * var(--radius));
  }

  .mat-icon {
    position: absolute;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: inherit;
    height: auto;
    font-size: inherit;
  }

  &.mat-primary:not(:disabled) {
    background-color: var(--primary-color);
    color: var(--font-contrast-color);
  }

  .mat-datepicker-toggle-default-icon {
    position: absolute;
    width: 1em;
    height: 1em;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

.mat-mdc-stroked-button:not(.mat-mdc-button-disabled) {
  border-color: currentcolor;
}

.mat-mdc-unelevated-button,
.mat-mdc-outlined-button {
  font-size: 1rem;
  font-weight: bold;
}

.mat-mdc-outlined-button.mat-primary {
  background-color: var(--background-color);
  border: none;
}

// Styles for 'mat-toggle-button' element.

.mat-button-toggle-group.mat-button-toggle-group-appearance-standard {
  border: 1px solid rgb(0 0 0 / 0.24);
}

.mat-button-toggle.mat-button-toggle-appearance-standard {
  background-color: var(--faint-light-color);
  color: var(--font-primary-light-color);

  .mat-button-toggle-button {
    height: 100%;
  }

  .mat-button-toggle-button .mat-button-toggle-label-content {
    padding: 0;
    line-height: 1rem;
    font-size: 1rem;
    font-weight: 500;
  }

  &.mat-button-toggle-checked {
    background-color: var(--primary-color);
    color: var(--font-contrast-color);
  }
}

.mat-mdc-unelevated-button.mat-primary.mat-mdc-button-base {
  color: var(--font-contrast-color);
  font-weight: 700;
}

.mdc-button.mdc-button--unelevated.mat-mdc-unelevated-button.mat-accent.mat-mdc-button-base {
  color: var(--font-primary-color);
  font-weight: 700;
}
