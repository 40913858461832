.dartsalesc-cell-wrapper {
  position: relative;

  /* Because we not want override this style from :host selector. */
  /* stylelint-disable-next-line declaration-no-important */
  display: flex !important;
  overflow: hidden;
  align-items: center;
  height: 100%;
  width: 100%;
  min-height: var(--cell-min-height, calc(var(--cell-height) - 1px));

  /* We need this padding to have space for cell outline. */
  --outline-width: 2px;

  padding: var(--outline-width);
}

/* We use 'box-shadow' for cell outline to correctly work with sticky cells. */
.dartsalesc-cell-wrapper.invalid,
.dartsalesc-cell-wrapper:has(.mat-mdc-select-invalid) {
  box-shadow: inset 0 0 0 1px var(--warn-color);

  &:focus-within {
    box-shadow: inset 0 0 0 2px var(--warn-color);
  }
}

.dartsalesc-cell-wrapper:focus-within:not(.invalid, .readonly) {
  box-shadow: inset 0 0 0 2px var(--primary-color);
}

.dartsalesc-cell {
  width: 100%;
  height: 100%;
  flex-shrink: 1;
  overflow: hidden;
}

.dartsalesc-cell_editor,
.dartsalesc-cell_editor [dartsalescCellEditorInput] {
  width: 100%;
  height: 100%;
  border: 0;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  text-align: inherit;

  /* We can disable outline here because we have ':focus-within' selector on parent element. */
  outline: none;
}
