.mat-mdc-select-panel .mat-pseudo-checkbox.mat-pseudo-checkbox-checked {
  /*
    For some reason <mat-select> without <form-control>
    uses 'accent' color for checkbox instead of primary.
  */
  background: var(--primary-color);
}

.mdc-menu-surface.mat-mdc-select-panel.mdc-menu-surface--open,
.mdc-menu-surface.mat-mdc-autocomplete-panel.mdc-menu-surface--open {
  padding: 0;
}

.mat-mdc-select-panel
  .mat-mdc-option[aria-disabled="true"].contains-mat-select-search {
  top: 0;
  margin-top: 0;
  padding-bottom: 8px;
}

.single-select-table-cell {
  .mat-mdc-select-value,
  .mat-mdc-select-arrow {
    color: inherit;
  }

  .mat-mdc-select-placeholder {
    color: var(--faint-color);
  }

  .mat-mdc-select-arrow {
    opacity: 0.5;
  }
}

.part-performer-select .mat-mdc-select-arrow {
  opacity: 0.5;
}

.mat-mdc-select-arrow-wrapper {
  overflow: hidden;
}
