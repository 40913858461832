.dartsalesc-loading {
  --spinner-color: white;
  --spinner-size: 12px;

  cursor: progress;
  position: relative;
  overflow: hidden;
}

.dartsalesc-loading::after {
  position: absolute;
  top: calc(50% - var(--spinner-size));
  left: calc(50% - var(--spinner-size));
  content: " ";
  display: block;
  width: var(--spinner-size);
  height: var(--spinner-size);
  margin: 5px;
  border-radius: 50%;
  border: 2px solid var(--spinner-color);
  border-color: var(--spinner-color) transparent var(--spinner-color)
    transparent;
  animation: dartsalesc-loading-spin 1.2s ease-in-out infinite;
  z-index: 1;
}

.dartsalesc-loading::before {
  position: absolute;
  inset: 0;
  background-color: rgb(0 0 0 / 0.3);
  animation: fadein 200ms ease-in;

  /* To balance loader positioning on flexbox */
  content: "";
}

@keyframes dartsalesc-loading-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
