h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: 500;
}

p {
  margin: 0;
}

/* Typography: https://projects.invisionapp.com/d/main#/console/22262762/472661067/preview */

body .headline-1 {
  font-size: 60px;
  letter-spacing: -1px;
  line-height: 112px;
  color: var(--font-primary-color);
}

body .headline-2 {
  font-size: 48px;
  letter-spacing: -0.4px;
  line-height: 72px;
  color: var(--font-primary-color);
}

body .headline-3 {
  font-size: 36px;
  letter-spacing: 0;
  line-height: 56px;
  color: var(--font-primary-color);
}

body .headline-4 {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 36px;
  color: var(--font-primary-color);
}

body .headline-5 {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.15px;
  line-height: 24px;
  color: var(--font-primary-color);
}

body .headline-6 {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.45px;
  line-height: 24px;
  color: var(--font-primary-color);
}

body .subtitle-1 {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.15px;
  line-height: 24px;
  color: var(--font-primary-color);
}

body .subtitle-2 {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.15px;
  line-height: 24px;
  color: var(--font-primary-color);
}

body .body-1 {
  font-size: 16px;
  letter-spacing: 0.3px;
  line-height: 24px;
  color: var(--font-primary-color);
}

body .body-2 {
  font-size: 14px;
  letter-spacing: 0.05px;
  line-height: 20px;
  color: var(--font-primary-color);
}

body .caption-1 {
  font-size: 12px;
  letter-spacing: 0.2px;
  line-height: 16px;
  color: var(--font-accent-color);
}

body .caption-2 {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 16px;
  color: var(--font-accent-color);
}

body .caption-1_faint {
  font-size: 12px;
  letter-spacing: 0.2px;
  line-height: 16px;
  color: var(--faint-color);
}

body .caption-button {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 16px;
  color: var(--font-primary-color);
}
