.points-list-component-menu.mat-mdc-menu-panel.mat-mdc-menu-panel,
.points-list-system-classifications-menu.mat-mdc-menu-panel.mat-mdc-menu-panel {
  width: 250px;
  max-height: 220px;
  border-radius: var(--radius);
  background-color: var(--accent-dark-color);
}

/* Disabling scrollbar for mat menu. */
.points-list-component-menu.mat-mdc-menu-panel.mat-mdc-menu-panel {
  overflow: hidden;
}

/*
 * Making all nested elements to have height same as menu,
 * so that scrollbar will be shown on projected content.
 */
.points-list-component-menu.mat-mdc-menu-panel.mat-mdc-menu-panel
  > .mat-mdc-menu-content,
.points-list-component-menu.mat-mdc-menu-panel.mat-mdc-menu-panel
  > .mat-mdc-menu-content
  > div {
  overflow: hidden;
  height: 100%;
}
