.points-list-part-form .mat-mdc-form-field .mdc-text-field--disabled,
.attachment-form .mat-mdc-form-field .mdc-text-field--disabled {
  --mdc-outlined-text-field-disabled-label-text-color: rgb(0 0 0 / 0.6);
  --mdc-outlined-text-field-disabled-input-text-color: var(
    --primary-light-contrast-color
  );
  --mdc-outlined-text-field-input-text-placeholder-color: var(
    --primary-light-contrast-color
  );
}

.points-list-part-form
  .mat-mdc-form-field
  .mdc-text-field--outlined.mdc-text-field--disabled,
.attachment-form
  .mat-mdc-form-field
  .mdc-text-field--outlined.mdc-text-field--disabled {
  --mdc-outlined-text-field-disabled-outline-color: rgb(0 0 0 / 0.4);
}

.points-list-part-form .mat-mdc-radio-button .mdc-radio--disabled + label {
  --mat-radio-disabled-label-color: var(--primary-light-contrast-color);
}

.points-list-part-form .mat-mdc-standard-chip.mdc-evolution-chip--disabled {
  opacity: 1;
}
