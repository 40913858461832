.organizations-select-menu {
  --menu-max-width: 380px;
  --menu-max-height: 275px;
}

.organizations-select .mat-mdc-button-base .mdc-button__label {
  width: 100%;
}

.organizations-select-menu.mat-mdc-menu-panel.mat-mdc-menu-panel {
  width: var(--menu-max-width);
  max-width: var(--menu-max-width);
  max-height: var(--menu-max-height);
}

.organizations-select-menu
  .mat-mdc-list-item.mdc-list-item--with-leading-checkbox {
  height: 50px;
  padding: var(--space-s);
}

.organizations-select-menu .mat-mdc-list-item .mdc-list-item__start {
  margin: 0;
}

.organizations-select-menu .mat-mdc-selection-list {
  padding: 0;
}

.organizations-select-menu
  .mdc-list-item__content
  .mdc-list-item__primary-text {
  display: block;
}
