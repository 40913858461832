.estimate-table .mat-mdc-row,
.estimate-table .mat-mdc-header-row {
  background-color: var(--background-color);
}

.estimate-table .mat-mdc-header-row .mat-mdc-header-cell {
  color: var(--faint-color);
  font-size: 0.9rem;
}

.estimate-table .mat-mdc-table.base-table {
  background-color: transparent;
}
