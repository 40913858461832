.system-and-parts-tabs .mat-mdc-tab-body-wrapper {
  height: 100%;
}

.system-and-parts-tabs .mat-mdc-tab-body-active {
  height: 100%;
}

.system-and-parts-tabs .mat-mdc-tab-body.mat-mdc-tab-body-active {
  overflow-y: hidden;
}

.system-and-parts-tabs .mat-mdc-tab-body .mat-mdc-tab-body-content {
  overflow: hidden;
}
