.dialog-container-with-no-overflow .mdc-dialog .mdc-dialog__surface {
  overflow-y: hidden;
}

.dialog-actions {
  text-align: right;
}

.dialog-actions .dialog-actions__button {
  --btn-size: 140px;

  min-width: var(--btn-size);
  margin-left: var(--space-l);
}
