.group-management-menu.mat-mdc-menu-panel {
  width: 300px;
}

.terms-row-menu.mat-mdc-menu-panel {
  /* Material uses hack with repeated class name. */
  /* stylelint-disable-next-line declaration-no-important */
  min-width: 160px !important;
}

.menu-actions {
  display: flex;
  justify-content: flex-end;
}

.mat-mdc-menu-item.mdc-list-item .menu-actions__icon {
  color: var(--font-primary-light-color);
}

.mat-mdc-menu-item.mdc-list-item.menu-actions__button_delete,
.mat-mdc-menu-item.mdc-list-item.menu-actions__button_delete
  .menu-actions__icon {
  color: var(--warn-dark-color);
}
